<template>
  <div class="container">
    <div v-if="!isLoading" class="view-content">
      <div class="view-header">
        <div class="tag-wrap">
          <st-tag>
            {{ category_name }}
          </st-tag>
          <st-tag :color="isAnswer ? 'active' : 'unactive'">
            {{ isAnswer ? "답변완료" : "답변대기중" }}
          </st-tag>
        </div>
        <h2>
          {{ title }}
        </h2>
        <div>
          <st-date :isBeforeLine="true">
            {{ created_at }}
          </st-date>
        </div>
      </div>

      <section class="view-main-section">
        <div v-html="content" />
      </section>

      <div class="sub-title" v-show="attachs.length !== 0">
        <span>첨부파일 · {{ attachs.length }}개</span>
      </div>

      <section class="attach-area">
        <ul class="attach-list" :style="previewStyle">
          <li class="attach-list-item" v-for="item in attachs" :key="item.id">
            <img
              :src="item.url"
              alt="qna image"
              :title="item.name"
              @click="onViewImage(item)"
            />
          </li>
        </ul>
      </section>

      <div class="sub-title reply-line">
        <span>답변</span>
        <span class="reply-date" v-if="answer">{{ answer.created_at }}</span>
      </div>
      <section>
        <div class="reply-area">
          <div class="empty" v-if="!isAnswer">
            <p>최대한 빠른 답변 드리겠습니다.</p>
            <p>이용해 주셔서 감사합니다.</p>
          </div>
          <div class="reply-wrap" v-else v-html="answer.content" />
        </div>
      </section>
    </div>

    <div class="bottom-fixed-wrap">
      <button class="back" @click="$router.go(-1)">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-left"
          class="svg-inline--fa fa-arrow-left fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
          ></path>
        </svg>
      </button>

      <div v-if="isMyQna" class="tool-box">
        <button class="btn edit" @click="goEdit">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="edit"
            class="svg-inline--fa fa-edit fa-w-18"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
            ></path>
          </svg>
        </button>

        <button class="btn delete" @click="onDelete">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="trash-alt"
            class="svg-inline--fa fa-trash-alt fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
            ></path>
          </svg>
        </button>
      </div>
    </div>

    <st-modal v-model:value="toggle">
      <template v-slot:header>
        <span style="font-size: 13px; text-align: center; display: block">
          닫으시려면 빈공간을 눌러주세요.
        </span>
      </template>
      <div>
        <img
          style="width: 100%"
          v-if="imageItem"
          :src="imageItem.url"
          alt="extend-image"
          :title="imageItem.name"
        />
      </div>
    </st-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import StModal from "../ui/StModal";

export default {
  name: "View",
  data: () => ({
    id: null,
    title: null,
    content: null,
    created_at: null,
    attachs: [],
    answer: null,
    user_id: null,
    category: null,
    isError: false,
    // modal
    toggle: false,
    imageItem: null,
    // view data
    temp: null,
  }),
  components: {
    StModal,
  },
  computed: {
    category_name() {
      return (this.category && this.category.name) || "카테고리 없음";
    },

    previewStyle() {
      const width = this.attachs.length * (140 + 10) + 32;
      return `width: ${width}px`;
    },

    isLoading() {
      return this.$store.state.loading;
    },

    isAnswer() {
      return this.answer !== null;
    },

    isMyQna() {
      if (!this.$store.state.user) return false;
      return this.user_id === this.$store.state.user.user_id;
    },
  },
  methods: {
    onViewImage(item) {
      this.toggle = !this.toggle;
      this.imageItem = item;
    },

    goEdit() {
      this.$store.commit("SET_EDIT_VIEW_DATA", this.temp);
      this.$router.push(`/write/${this.id}`);
    },

    onDelete() {
      this.$confirm({
        title: "문의삭제",
        content: "해당 문의를 삭제하시겠습니까?",
        onConfirm: async () => {
          try {
            await this.$api.remove(this.id);
            this.$alert.success("문의가 정상적으로 삭제가 완료되었습니다.");
            this.$router.push("/list");
            this.$store.dispatch("CLEAR_AND_GET_LIST");
          } catch (err) {
            this.$alert.error("삭제 중 문제가 발생했습니다.");
          }
        },
      });
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    if (!id) {
      this.$router.push("/list");
      return;
    }

    this.id = id;
    this.$store.commit("TOGGLE_LOADING");

    try {
      const response = await this.$api.getView(id);

      if (!response.data) {
        this.isError = true;
        return;
      }

      const {
        answer,
        attachs,
        title,
        content,
        category_id,
        created_at,
        user_id,
      } = response.data;

      this.temp = response.data;

      if (answer) {
        this.answer = {
          ...answer,
          content: answer.content.replace(/\n/g, "<br/>"),
          created_at: dayjs(answer.created_at).format(
            "YYYY년 MM월 DD일 H시 m분"
          ),
        };
      }

      this.attachs = attachs;
      this.title = title;
      this.user_id = user_id;
      this.content = content.replace(/\n/g, "<br/>");
      this.created_at = dayjs(created_at).format("YYYY년 MM월 DD일 H시 m분");
      this.category = this.$store.state.categories.find(
        ({ id }) => id === category_id
      );

      this.$store.commit("TOGGLE_LOADING");
    } catch (error) {
      this.isError = true;
      this.$store.commit("TOGGLE_LOADING");
      console.log(error);
    }
  },

  unmounted() {
    Object.assign(this.$data, this.$options.data());
  },
};
</script>

<style lang="scss">
@import "~@/color";

.view-content {
  padding: 20px 16px 130px;

  .view-header {
    h2 {
      margin: 16px 0;
      font-size: 24px;
      word-break: break-all;
      line-height: 1.2;
    }

    .tag-wrap {
      & > * + * {
        margin-left: 6px;
      }
    }
  }

  .view-main-section {
    margin: 20px 0;
    padding: 20px 12px;
    background-color: white;
    border-radius: 8px;
  }

  section {
    font-size: 15px;
    width: 100%;
    overflow-x: auto;
  }

  .sub-title {
    padding: 6px 0;

    span {
      font-size: 16px;
      color: $gray08;
      font-weight: 600;
    }

    .reply-date {
      font-size: 12px;
      font-weight: 400;
      margin-left: 10px;
    }
  }

  .attach-area {
    margin: 16px 0;
  }

  .attach-list {
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;

    &-item {
      overflow: hidden;
      border-radius: 16px;
      width: 140px;
      height: 100px;
      border: 1px solid #e0e0e0;
      cursor: pointer;

      & + .attach-list-item {
        margin-left: 10px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .reply-line {
    display: flex;
    align-items: center;
    padding: 16px 0 0;
  }

  .reply-area {
    margin: 16px 0px;
    padding: 16px 12px;
    font-size: 14px;
    background-color: white;
    border-radius: 8px;

    .empty {
      color: $gray06;
      line-height: 1.4;
    }
  }
}

.bottom-fixed-wrap {
  position: fixed;
  bottom: 24px;
  right: 16px;
  left: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .tool-box {
    display: inline-flex;
    flex-direction: column;

    svg {
      width: 20px;
      height: 20px;
    }

    button + button {
      margin-top: 12px;
    }

    button {
      width: 46px;
      height: 46px;
      border: none;
      border-radius: 50%;
      color: white;
      text-align: center;
      background-color: $gray08;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
